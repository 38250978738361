<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Print Reciept</h3>
            </div>
            <div class="card-body">
              <p>Receipt Table placeholder {$receipt}</p>
              <br />
              <div class="row1">
                <div class="table-responsive">
                  <p>Header</p>
                  <div class="document-editor">
                    <div class="document-editor__toolbar"></div>
                    <div class="document-editor__editable-container">
                      <div class="document-editor__editable">
                        <ckeditor
                          @ready="onReady"
                          :editor="editor"
                          v-model="model.header"
                          :config="editorConfig"
                          @fileUploadRequest="onFileUploadRequest($event)"
                          @fileUploadResponse="onFileUploadResponse($event)"
                        ></ckeditor>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="table-responsive">
                  <p>Body</p>
                  <div class="document-editor">
                    <div class="document-editor__toolbar"></div>
                    <div class="document-editor__editable-container">
                      <div class="document-editor__editable">
                        <ckeditor
                          @ready="onReady"
                          :editor="editor"
                          v-model="model.email"
                          :config="editorConfig"
                          @fileUploadRequest="onFileUploadRequest($event)"
                          @fileUploadResponse="onFileUploadResponse($event)"
                        ></ckeditor>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="table-responsive">
                  <p>Footer</p>
                  <div class="document-editor">
                    <div class="document-editor__toolbar"></div>
                    <div class="document-editor__editable-container">
                      <div class="document-editor__editable">
                        <ckeditor
                          @ready="onReady"
                          :editor="editor"
                          v-model="model.footer"
                          :config="editorConfig"
                          @fileUploadRequest="onFileUploadRequest($event)"
                          @fileUploadResponse="onFileUploadResponse($event)"
                        ></ckeditor>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="table-responsive">
                  <p>Logo</p>
                  <file-input @change="filesChange"></file-input>
                </div>
                <br />
                <!-- <div class="table-responsive">
                  <base-input label="Logo Position">
                    <el-select
                      style="width: 100%"
                      v-model="model.position"
                      placeholder="Logo Position"
                    >
                      <el-option
                        v-for="option in positionArray"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div> -->
                <div
                  class="card-footer d-flex justify-content-end"
                  :class="type === 'dark' ? 'bg-transparent' : ''"
                >
                  <!-- <base-pagination total="30"></base-pagination> -->
                </div>
              </div>
            </div>

            <div v-if="isLoading">
              <base-alert type="success" dismissible>
                <span class="alert-inner--text"
                  ><strong>Success!</strong> Record Created Successfully</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>

            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-4">
                <button
                  type="button"
                  class="btn btn-default mb-4"
                  v-on:click="save()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const emailRepository = RepositoryFactory.get("email");
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import FileInput from "@/components/Inputs/FileInput";
import { ElSelect, ElOption } from "element-plus";
export default {
  components: {
    FileInput,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  data() {
    return {
      positionArray: [
        { id: "Left", name: "Left" },
        { id: "Right", name: "Right" },
      ],
      tableData: [],
      model: {},
      isLoading: false,
      editor: DecoupledEditor,
      editorData: "<p>Rich-text editor content.</p>",
      editorConfig: {
        // The configuration of the rich-text editor.
      },
    };
  },
  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    async save() {
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await emailRepository.create(data);
      if (response.data.status == 100 || response.data.status == 101) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
    async getEmail() {
      this.tableData = await emailRepository.getEmail();
      if (this.tableData.data) {
        if (typeof this.tableData.data[1] != undefined) {
          this.model = this.tableData.data[1];
        }
      }
    },
    onFileUploadRequest(evt) {
      console.log(evt);
    },
    onFileUploadResponse(evt) {
      console.log(evt);
      this.model.file = evt;
    },
    async filesChange(files) {
      this.model.file = "";

      let file = files[0];

      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var comp = this;
        reader.onload = function () {
          comp.onFileUploadResponse(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    },
  },
  async mounted() {
    await this.getEmail();
  },
};
</script>
<style>
.document-editor__editable-container .ck-editor__editable {
  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}
</style>
